import * as React from 'react';
import * as Styled from './styled';
import { TimerChunk } from '../../api/types/types';
import { Checkbox, IconButton, TextField, Popover, Button } from '@material-ui/core';
import { Delete, Save, ViewHeadline } from '@material-ui/icons';
// import { InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import DateFnsUtils from '@date-io/date-fns';
import NarrativeField from 'components/NarrativeField';
import { FeaturesConsumer } from 'common/FeaturesProvider';
import { Features, TimeKeeperAssignment } from '../../api/types/types';
import { countryTimeZone } from '../../App';
import { inject, observer } from 'mobx-react';
import EditTimerTimeDialogStore from 'store/Timer/edit.timer.time.dialog.store';
import { RootStore } from 'store/root.store';
import { TKConsumer } from 'common/TKProvider';
import { withTranslation } from 'react-i18next';

interface Props {
    segment: TimerChunk;
    selected?: boolean;
    segmentChange?: (newSeg: TimerChunk) => void;
    onEdit?: () => void;
    onDelete?: () => void;
    toggleSelect: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    timeEntryOpen?: () => void;
    style?: React.CSSProperties;
    updateSegmentNarrative?: (chunk: TimerChunk, descr: string) => void;
    clearSelections?: () => void;
    shouldUpdate?: boolean;
    setChunkPropsUpdate?: (val: boolean) => void;
    editTimerTimeDialogStore?: EditTimerTimeDialogStore;
    // tslint:disable-next-line:no-any
    t: any;
}
export function getDurStringBetween(from: Date, to: Date ): string {
    let msecs = to.getTime() - from.getTime();
    let secs = Math.floor(msecs / 1000);
    let hours   = Math.floor(secs / 3600);
    let minutes = Math.floor((secs - (hours * 3600)) / 60);
    let seconds;
    if (to.getSeconds() >= from.getSeconds()) {
        seconds = to.getSeconds() - from.getSeconds();
    } else {
        seconds = 60 - from.getSeconds() + to.getSeconds();
    }
    let hstring = `${hours}`;
    let sstring = `${seconds}`;
    let mstring = `${minutes}`;
    if (hours   < 10) {hstring   = '0' + hstring; }
    if (minutes < 10) {mstring = '0' + mstring; }
    if (seconds < 10) {sstring = '0' + sstring; }
    return `${hstring}:${mstring}:${sstring}`
}
interface State {
    // tslint:disable-next-line:no-any
    anchorEl?: any,
    narrative: string,
    isStateNarrative: boolean,
    saveIconDisabled: boolean
}
@inject((allStores: { rootStore: RootStore }) => {
    return {
        editTimerTimeDialogStore: allStores.rootStore.editTimerChunkTimeDialogStore
    }
})
@observer
class TimerChunkItem extends React.Component<Props, State> {
    state: State = {
        anchorEl: null,
        narrative: '',
        isStateNarrative: false,
        saveIconDisabled: false,
    }
    constructor(props: Props) {
        super(props);
        this.setState({
            isStateNarrative: false,
            narrative: ''
        })
    }
    
    componentWillUnmount(): void {
        if (this.props.updateSegmentNarrative && this.state.isStateNarrative) {
            this.props.updateSegmentNarrative(this.props.segment, this.state.narrative);
        }
    }
    
    componentWillReceiveProps(nextProps: Readonly<Props>): void {
        if (nextProps.selected || nextProps.shouldUpdate) {
            if (this.props.updateSegmentNarrative && this.state.isStateNarrative) {
                this.props.updateSegmentNarrative(this.props.segment, this.state.narrative);
            }
            this.setState({
                isStateNarrative: false,
                saveIconDisabled: false
            });
            if (this.props.setChunkPropsUpdate) {
                this.props.setChunkPropsUpdate(false);
            }
        }
    }
    componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): void {
        if (nextProps.selected && nextState.isStateNarrative && this.props.clearSelections) {
            this.props.clearSelections();
        }
    }

    saveNarrative = () => {
        const text: string = this.state.narrative;
        const newChunk = JSON.parse(JSON.stringify(this.props.segment));
        if (newChunk.description === text) {
            return;
        }
        newChunk.description = text;
        if (this.props.segmentChange) {
            this.props.segmentChange(newChunk);
        }
        this.setState({
            isStateNarrative: false,
            saveIconDisabled: false
        });
    }
    handleDateChange = (date: Date) => {
        let dateTimeType = DateTime.fromJSDate(date);
        const newChunk = JSON.parse(JSON.stringify(this.props.segment));
        let validateEndTime = dateTimeType > DateTime.fromISO(newChunk.startTime);
        newChunk.endTime = validateEndTime ?
            dateTimeType.toISO() :
            (DateTime.fromISO(newChunk.startTime).plus({ seconds: 1 })).toISO();
        if (this.props.segmentChange) {
            this.props.segmentChange(newChunk);
        }
        this.setState({ anchorEl: null });
    }
    handleClick = async (evt: React.MouseEvent<HTMLDivElement>, id: number) => {
        const store = this.props.editTimerTimeDialogStore!;
        if (!store.rootStore.appStore.currentTimekeeper.writable) { return }
        if (id) {
            if (store.rootStore.appStore.online && id < 0) {
                evt.preventDefault();
                return;
            }
            await store.open(this.props.segment).catch(() => null);
        }
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    onChangeNarrative = (text: string) => {
        if (text === this.props.segment.description) {
            this.setState({
                isStateNarrative: false,
                saveIconDisabled: false
            });
            return;
        }
        this.setState({
            narrative: text,
            isStateNarrative: true,
            saveIconDisabled: true
        });
    }
    onBlur = (text: string) => {
        if (text === this.props.segment.description) {
            this.setState({
                isStateNarrative: false,
                saveIconDisabled: false
            });
        }
    }
    render() {
        const { segment, selected, onDelete, toggleSelect, timeEntryOpen, style, t } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const countrycode = countryTimeZone.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        // TODO: Internationalization date
        const locale = (countrycode.country || 'US').toUpperCase() === 'US' ? 'en-US' : 'en-GB';
        const online = this.props.editTimerTimeDialogStore!.rootStore.appStore.online;
        
        return (
            <FeaturesConsumer>
                {(features: Features) =>
            <TKConsumer>
                { (tk: TimeKeeperAssignment) =>
            <div style={style}>
                <Styled.SegmentPaper
                    submitted={segment.submitted} 
                    hasEntry={!!segment.timeEntryId} 
                    square={true}
                >
                    <Styled.Control>
                        {
                            segment.timeEntryId ? 
                                <ViewHeadline 
                                    onClick={timeEntryOpen} 
                                    style={{
                                        color: segment.submitted ? '#4bb361' : '#ff9800',
                                        cursor: 'pointer'
                                    }}
                                /> :
                                <Checkbox
                                    disabled={!tk.writable}
                                    checked={!!selected}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(evt) => toggleSelect(evt)}
                                    disableRipple={true}
                                    style={{padding: 0}}
                                />}
                    </Styled.Control>
                    <Styled.DateContainer>
                        {(new Date(segment.startTime)).toLocaleDateString(locale, 
                            {year: 'numeric', month: '2-digit', day: '2-digit'})}
                    </Styled.DateContainer>
                    <Styled.TimeContainer onClick={(evt) => this.handleClick(evt, segment.id!)}>
                        {getDurStringBetween(new Date(segment.startTime), new Date(segment.endTime))}
                    </Styled.TimeContainer>
                    {/* TODO: the value of open relies on state.anchorEl which is always null */}
                    <Popover
                        id="simple-popper"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Styled.Popover>
                            <TextField
                                id="time"
                                label={t('start_time', { ns: 'timer' })}
                                value={new Date(segment.startTime).toLocaleTimeString()}
                                disabled={true}
                            />
                            {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                            {/*    <InlineTimePicker*/}
                            {/*        margin="normal"*/}
                            {/*        label="End Time"*/}
                            {/*        value={new Date(segment.endTime)}*/}
                            {/*        onChange={this.handleDateChange}*/}
                            {/*        format={`h:mm:ss a`}*/}
                            {/*        seconds={true}*/}
                            {/*        disabled={!!segment.timeEntryId}*/}
                            {/*    />*/}
                            {/*</MuiPickersUtilsProvider>*/}
                        </Styled.Popover>
                    </Popover>
                    <Styled.NarrativeContainer>
                        <NarrativeField
                            onChange={this.onChangeNarrative}
                            onBlur={this.onBlur}
                            value={this.state.isStateNarrative ? this.state.narrative : segment.description}
                            disabled={
                                !!segment.timeEntryId ||
                                !tk.writable ||
                                (online && segment.id! < 0)
                            }
                            placeholder={t('selected_timer.chunk.field.narrative.placeholder')}
                            maxLength={features.EpochConfigNarrativesMaximumChars}
                            onEnter={() => { return true }}
                            style={{minHeight: 52, overflowY: 'scroll', overflowX: 'hidden'}}
                        />
                        {
                            this.state.isStateNarrative && 
                            <Styled.Star>*</Styled.Star>
                        }
                    </Styled.NarrativeContainer>
                   {(!segment.timeEntryId && tk.writable) &&  <Styled.ActionContainer>
                       {this.state.saveIconDisabled ? 
                        <Styled.CompactIconButton onClick={this.saveNarrative} title={t('save', { ns: 'common' })}>
                            <Save fontSize="small" />
                        </Styled.CompactIconButton> :
                        <Styled.CompactIconButton onClick={onDelete} title={t('delete', { ns: 'common' })}>
                            <Delete fontSize="small" />
                        </Styled.CompactIconButton>}
                    </Styled.ActionContainer>}
                </Styled.SegmentPaper>
            </div>
                }
            </TKConsumer>}
            </FeaturesConsumer>
        )
    }
}

export default withTranslation(['timers', 'common'])(TimerChunkItem);